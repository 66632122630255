
.day {
	$self: &;
	box-sizing: border-box;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0;
	white-space: normal;
	cursor: pointer;
	transition: all 0.5s;

	&.active {
		padding: 2vw;
	}

	// darken
	&::before {
		content: '';
		display: none;
		position: fixed;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.5);
		opacity: 0;
		pointer-events: none;
		transition: all 1s;
	}

	&-enter {
		&::before {
			display: block;
		}
	}
	&-enter-active,
	&-enter-done {
		&::before {
			display: block;
			opacity: 1;
		}
	}
	&-exit,
	&-exit-active {
		&::before {
			display: block;
			opacity: 0;
		}
	}

	&__asset {
		flex: 1 1 auto;
		max-height: 100%;
		min-height: 50%;
		filter: drop-shadow(0.25px 0.25px 0.5px rgba(0,0,0,0.5));
		transition: all 0.2s;

		#{$self}:not(.active):hover &,
		#{$self}:not(.active):focus & {
			transform: scale(1.05);
			filter: drop-shadow(0.5px 0.5px 1px rgba(0,0,0,0.5));
		}
	}

	img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
		margin: 0 auto;

	}
}

.day-info {
	display: none;
	padding: 0;
	margin: 0;
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: 0%;
	font-size: 0rem;
	min-height: 0;
	background-color: #e9ecf6;
	overflow: hidden;
	transition: all 0.5s;

	.day-enter-active & {
		display: block;
		flex-basis: auto;
	}
	.day-enter-done & {
		display: block;
		flex-basis: auto;
		font-size: 1.5rem;
		padding: 1rem 2rem;
		margin-top: 1em;
	}
	.day-exit &,
	.day-exit-active & {
		display: block;
		flex-basis: 0%;
	}

	&__text {
		margin: 0;
		font-family: 'Satisfy', cursive;
		color: #3a3737;
	}
}
