
.app {
	position: relative;
}

.dayLabel {
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 99;
	padding: 0.5rem;
	font-size: 1.2rem;
	font-weight: bold;
	color: #710f11;
	filter: drop-shadow(0 0 0.1em rgba(255,255,255,0.75));
}
