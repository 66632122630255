
.scene-card {
	position: absolute;
	color: white;
	text-align: center;
	opacity: 0;
	transition: all 1s;

	&.loaded {
		opacity: 1;
		background-color: white;

		&::after {
			animation-name: shiny;
			animation-duration: 1s;
			animation-iteration-count: 1;
		}
	}

	&.open {

		.peel-top,
		.peel-back,
		.peel-bottom-shadow {
			display: none;
		}
	}

	&::after {
		content: '';
		position: absolute;
		z-index: 4;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: linear-gradient(135deg, transparent 42%, white 42%, white 58%, transparent 58%);
		background-size: 300% 100%;
		opacity: 0;
		pointer-events: none;
	}

	.peel-top {

		img {
			display: block;
			width: 100%;
			height: 100%;
		}
	}

	.peel-bottom {
		box-sizing: border-box;
		padding: 5%;
		box-shadow: inset 0.5px 0.5px 4px rgba(0,0,0,0.5);
	}
}

@keyframes shiny {
	from {
		opacity: 1;
		background-position: right;
	}
}
