.clouds {
	height: 100%;
	display: flex;
}
.clouds img {
	animation-name: cloud-transit;
	animation-duration: 240s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

@keyframes cloud-transit {
	from {
		transform: translateX(-100%);
	}
	to {
		transform: translateX(0%);
	}
}
